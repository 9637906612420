<script setup lang="ts">
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import type { CookieConsentConfig } from 'vanilla-cookieconsent'
import * as CookieConsent from 'vanilla-cookieconsent'
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'

const ccStore = useCookieConsentStore()

const config: CookieConsentConfig = {
  cookie: {
    name: 'zeno_cc_cookie',
  },
  guiOptions: {
    consentModal: {
      layout: 'bar inline',
      position: 'bottom',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: ({ cookie }) => {
    ccStore.consented = true
    ccStore.categories = cookie.categories
    // console.log('onFirstAction fired', cookie)
  },

  onConsent: ({ cookie }) => {
    ccStore.consented = true
    ccStore.categories = cookie.categories
    // console.log('onConsent fired ...', cookie)
  },

  onChange: ({ cookie }) => {
    ccStore.consented = true
    ccStore.categories = cookie.categories
    // console.log('onChange fired ...', cookie, changedCategories, changedServices)
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    targeting: {},
  },

  language: {
    default: 'en',

    translations: {
      en: {
        consentModal: {
          title: 'We Value Your Privacy',
          description:
            'Our website uses cookies to enhance your experience, analyze site usage, and personalize content. By continuing to use our site, you agree to our use of cookies. You can manage your preferences or find more details in our Cookie Policy.',
          acceptAllBtn: 'Accept',
          acceptNecessaryBtn: 'Reject',
          showPreferencesBtn: 'Manage preferences',
          footer: `
            <a href="/privacy.pdf">Privacy Policy</a>
<!--            <a href="#link">Impressum</a>-->
          `,
        },
        preferencesModal: {
          title: 'Cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Save preferences',
          sections: [
            {
              title: 'Cookie usage',
              description:
                'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy.pdf" class="cc-link">privacy policy</a>.',
            },
            {
              title: 'Strictly necessary cookies',
              description:
                'These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Performance and Analytics cookies',
              description:
                'We use performance/analytics cookies to analyze how the website is accessed, used, or is performing in order to provide you with a better user experience and to maintain, operate and continually improve the website.',
              linkedCategory: 'analytics',
              // cookieTable: {
              //   headers: {
              //     name: 'Cookie',
              //     domain: 'Domain',
              //     desc: 'Description',
              //   },
              //   body: [
              //     {
              //       name: '_ga',
              //       domain: 'yourdomain.com',
              //       desc: 'description ...',
              //     },
              //     {
              //       name: '_gid',
              //       domain: 'yourdomain.com',
              //       desc: 'description ...',
              //     },
              //   ],
              // },
            },
            // {
            //   title: 'Advertisement and targeting cookies',
            //   description:
            //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            //   linkedCategory: 'ads',
            // },
            {
              title: 'More information',
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:support@zenomedia.com">contact us</a>.',
            },
          ],
        },
      },
    },
  },
}

await CookieConsent.run(config)
</script>

<template>
  <div class="hidden" />
</template>

<style scoped></style>
